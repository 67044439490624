<template>
  <VxDataListItem
    data-testid="campaignDataListItem"
    class="campaign-list-item"
    @showItem="onShowItem"
  >
    <template #desktop>
      <td class="campaign-list-item__name">
        {{ campaign.name }}
      </td>
      <td class="campaign-list-item__description">
        <p>{{ description }}</p>
        <p>
          {{ $t("bounceback.setup.campaignListItem.couponsValid") }}
          <span>
            {{ redeemingPeriod }}
          </span>
        </p>
        <v-chip
          v-for="tag in tags"
          :key="tag.text"
          class="mr-2"
          :color="tag.color"
          dark
        >
          {{ tag.text }}
        </v-chip>
      </td>
    </template>
    <template #mobile>
      <v-list-item-content>
        <v-list-item-title class="campaign-list-item__name">
          {{ campaign.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <p>
            {{ $t("bounceback.setup.campaignListItem.couponsValid") }}
            <span>
              {{ redeemingPeriod }}
            </span>
          </p>
          <v-chip
            v-for="tag in tags"
            :key="tag.text"
            :color="tag.color"
            class="mr-2"
            dark
          >
            {{ tag.text }}
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </VxDataListItem>
</template>

<script>
import { format, isEqual, parseISO } from "date-fns";
import { VxDataListItem } from "@/core-ui";
import { CampaignType } from "../../constants";

const formatDatePeriod = (startDate, endDate, dateFormat = "MM/dd/yy") => {
  const start = format(startDate, dateFormat);
  const end = format(endDate, dateFormat);
  return isEqual(startDate, endDate) ? start : `${start} - ${end}`;
};

export default {
  name: "CampaignListItem",
  components: {
    VxDataListItem,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isManualType() {
      return this.campaign.campaignType === CampaignType.MANUAL;
    },
    description() {
      const { redeemAmount, redeemMinSpend, earnMinSpend } = this.campaign;

      if (this.isManualType) {
        return this.$t("bounceback.setup.campaignListItem.manualDescription", {
          redeemAmount,
          redeemMinSpend,
          earningPeriod: this.earningPeriod,
        });
      }

      return this.$t("bounceback.setup.campaignListItem.autoDescription", {
        redeemAmount,
        redeemMinSpend,
        earningPeriod: this.earningPeriod,
        earnMinSpend,
      });
    },
    earningPeriod() {
      const { earnStartOn, earnEndOn } = this.campaign;
      return formatDatePeriod(parseISO(earnStartOn), parseISO(earnEndOn));
    },
    redeemingPeriod() {
      const { redeemStartOn, redeemEndOn } = this.campaign;
      return formatDatePeriod(parseISO(redeemStartOn), parseISO(redeemEndOn));
    },
    tags() {
      const tags = [];
      const addTag = (text, color = "") => tags.push({ text, color });

      if (!this.campaign.active) {
        addTag(this.$t("bounceback.setup.campaignListItem.tags.ended"));
      }

      if (this.campaign.earnable) {
        addTag(this.$t("bounceback.setup.campaignListItem.tags.earnable"));
      }

      if (this.campaign.redeemable) {
        addTag(this.$t("bounceback.setup.campaignListItem.tags.redeemable"));
      }

      if (this.isManualType) {
        addTag(
          this.$t("bounceback.setup.campaignListItem.tags.manual"),
          "primary"
        );
      }

      return tags;
    },
  },
  methods: {
    onShowItem() {
      this.$emit("click", this.campaign);
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-list-item td {
  padding: 16px 0px !important;
  vertical-align: top;
}

.campaign-list-item__name {
  font-weight: bold;
  text-transform: uppercase;
}

td.campaign-list-item__name {
  width: 230px;
}
</style>
