<template>
  <RaiSetups v-model="activeSetup" :loading="loading">
    <CampaignListStep v-bind="$props" :campaigns="campaigns" />
    <TextWithLinksStep
      :title="$t('bounceback.setup.trainTeam.title')"
      :sections="sections"
    />
  </RaiSetups>
</template>

<script>
import TextWithLinksStep from "@/views/Common/TextWithLinksStep";
import CampaignListStep from "./components/CampaignListStep/CampaignListStep.vue";
import { RaiSetups } from "@/core-ui";
import { GET_CAMPAIGN_LIST } from "./graphql/queries";

export default {
  name: "BouncebackSetupView",
  components: {
    RaiSetups,
    CampaignListStep,
    TextWithLinksStep,
  },
  props: {
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    activeSetup: 0,
    campaigns: [],
    loading: false,
  }),
  computed: {
    sections() {
      return [
        {
          title: this.$t("bounceback.setup.trainTeam.step1.title"),
          description: this.$t("bounceback.setup.trainTeam.step1.description"),
          goTo: {
            text: this.$t("bounceback.setup.trainTeam.step1.linkText"),
            href: "https://help.resaleai.com/support/solutions/articles/154000045024-bounceback-training-",
          },
        },
        {
          title: this.$t("bounceback.setup.trainTeam.step2.title"),
          description: this.$t("bounceback.setup.trainTeam.step2.description"),
          goTo: {
            text: this.$t("bounceback.setup.trainTeam.step2.linkText"),
            href: "https://help.resaleai.com/support/solutions/articles/154000045033-gift-card-bounceback-training",
          },
        },
        {
          title: this.$t("bounceback.setup.trainTeam.step3.title"),
          description: this.$t("bounceback.setup.trainTeam.step3.description"),
          goTo: {
            text: this.$t("bounceback.setup.trainTeam.step3.linkText"),
            href: "https://help.resaleai.com/support/solutions/articles/154000045028-how-to-redeem-bouncebacks",
          },
        },
        {
          title: this.$t("bounceback.setup.trainTeam.step4.title"),
          description: this.$t(
            "bounceback.setup.trainTeam.step.step4.description"
          ),
          goTo: {
            text: this.$t("bounceback.setup.trainTeam.step4.linkText"),
            href: "https://help.resaleai.com/support/solutions/articles/154000045029-bounceback-didn-t-print-",
          },
        },
      ];
    },
  },
  apollo: {
    campaigns: {
      query: GET_CAMPAIGN_LIST,
      watchLoading(isLoading) {
        this.setLoading(isLoading);
      },
      update(data) {
        return data.bouncebackCampaigns;
      },
    },
  },
  methods: {
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    onBack() {
      this.$router.back();
    },
  },
};
</script>
