<template>
  <RaiSetup
    :title="$t('bounceback.setup.campaignList.title')"
    v-bind="$attrs"
    type="info"
    hide-next
  >
    <template #loading>
      <v-row v-for="campaign in campaigns" :key="campaign.id" class="mb-5">
        <v-col cols="12" md="3">
          <v-skeleton-loader type="heading" />
        </v-col>
        <v-col cols="12" md="8">
          <v-skeleton-loader
            type="paragraph"
            :width="isMobile ? '100%' : '75%'"
          />
        </v-col>
      </v-row>
    </template>
    <template #default>
      <VxDataList
        class="campaign-data-list"
        empty-data-text="You have no active campaigns. Add a new campaign now, or view your past campaign details."
      >
        <template #body>
          <CampaignListItem
            v-for="campaign in displayedCampaigns"
            :key="campaign.id"
            :campaign="campaign"
            @click="onCampaignClick"
          />
        </template>
      </VxDataList>
      <v-row>
        <v-col cols="12" md="6">
          <VxBtn
            v-if="showViewPast"
            secondary
            class="px-0"
            data-testid="pastFutureCampaignsButton"
            @click="onViewPast"
          >
            {{ $t("bounceback.setup.campaignList.pastFutureButton") }}
          </VxBtn>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
          <VxBtn
            :block="isMobile"
            data-testid="bouncebackAddNewButton"
            @click="onAddNew"
          >
            <v-icon left>$plus</v-icon>
            {{ $t("bounceback.setup.campaignList.addNewButton") }}
          </VxBtn>
        </v-col>
      </v-row>
    </template>
  </RaiSetup>
</template>

<script>
import CampaignListItem from "./CampaignListItem.vue";
import { VxDataList, VxBtn, RaiSetup, useResponsiveness } from "@/core-ui";
import orderBy from "lodash/orderBy";

export default {
  name: "CampaignListStep",
  components: {
    VxDataList,
    VxBtn,
    RaiSetup,
    CampaignListItem,
  },
  mixins: [useResponsiveness()],
  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    viewPastCampaigns: false,
  }),
  computed: {
    showViewPast() {
      return (
        !this.viewPastCampaigns &&
        this.campaigns.some((campaign) => !campaign.active)
      );
    },
    displayedCampaigns() {
      const activeCampaigns = this.campaigns.filter(
        (campaign) => campaign.active
      );

      if (!this.viewPastCampaigns) {
        return activeCampaigns;
      }

      const nonActiveCampaigns = this.campaigns.filter(
        (campaign) => !campaign.active
      );
      const sortedNonActiveCampaigns = orderBy(
        nonActiveCampaigns,
        (c) => c.earnStartOn,
        ["desc"]
      );

      return [...activeCampaigns, ...sortedNonActiveCampaigns];
    },
  },
  methods: {
    onAddNew() {
      this.$router.push({ name: this.raiLinkTo.addNewCampaign });
      this.$emit("add");
    },
    onCampaignClick(campaign) {
      this.$router.push({
        name: this.raiLinkTo.editCampaign,
        params: { campaignId: campaign.id },
      });

      this.$emit("click", campaign);
    },
    onViewPast() {
      this.viewPastCampaigns = true;
      this.$emit("view-past");
    },
  },
};
</script>

<style lang="scss">
.campaign-data-list .vx-data-list table {
  padding: 0 0 16px 0;
}
</style>
