export const CampaignType = {
  AUTOMATIC: "automatic",
  MANUAL: "manual",
};

export const IntentType = {
  BACK_TO_SCHOOL: "Back to school",
  HOLIDAY: "Holiday",
  BLACK_FRIDAY: "Black Friday",
  SPRING: "Spring",
  SUMMER: "Summer",
  FALL: "Fall",
  OTHER: "Other",
};
