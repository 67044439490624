import gql from "graphql-tag";

const COUPON_FRAGMENT = gql`
  fragment COUPON_FRAGMENT on Coupon {
    id
    name
    email
    cellphone
    number
    transactionId
    redeemed
    voided
    createdAt
    redeemAmount
    redeemMinSpend
    redeemStartOn
    redeemEndOn
    redeemable
    createdAt
  }
`;

export const COUPON_PRINT = gql`
  query COUPON_PRINT($id: ID!) {
    coupon(id: $id) {
      id
      transId
      customerName
      redeemStartOn
      redeemEndOn
      redeemAmount
      redeemMinSpend
      redeemLocations
      employeeName
      couponTerms
      couponCode
      storeCallAndText
      storeName
      storeAddress
      storeCity
      storeState
      storeZip
      storeWebsite
      storeNumber
      brand
    }
  }
`;

// MUTATIONS
export const UPDATE_COUPON = gql`
  mutation UPDATE_COUPON($input: UpdateCouponInput!) {
    updateCoupon(input: $input) {
      coupon {
        ...COUPON_FRAGMENT
      }
      errors
    }
  }
  ${COUPON_FRAGMENT}
`;

export const CREATE_COUPON = gql`
  mutation CREATE_COUPON($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      coupons {
        ...COUPON_FRAGMENT
      }
      errors
    }
  }
  ${COUPON_FRAGMENT}
`;

export const REDEEM_COUPON = gql`
  mutation REDEEM_COUPON($id: ID!) {
    redeemCoupon(id: $id) {
      coupon {
        ...COUPON_FRAGMENT
      }
      errors
    }
  }
  ${COUPON_FRAGMENT}
`;

// BOUNCEBACK CAMPAIGN QUERY
export const GET_CAMPAIGN_BY_ID = gql`
  query GET_CAMPAIGN_BY_ID($id: ID!) {
    bouncebackCampaign(id: $id) {
      id
      name
      earnStartOn
      earnEndOn
      redeemStartOn
      redeemEndOn
      earnMinSpend
      earnMultiple
      redeemMinSpend
      redeemAmount
      campaignType
      reminderText
      intent
      hasCoupons
      redeemable
      earnable
    }
  }
`;

export const GET_CAMPAIGN_LIST = gql`
  query GET_CAMPAIGN_LIST {
    bouncebackCampaigns {
      id
      name
      earnMinSpend
      redeemMinSpend
      redeemAmount
      redeemStartOn
      redeemEndOn
      earnStartOn
      earnEndOn
      redeemable
      earnable
      active
    }
  }
`;
