<template>
  <RaiSetup v-bind="$props" type="info" :hide-next="hideNext" v-on="$listeners">
    <template #extension>
      <slot name="extension" />
    </template>
    <template #loading>
      <div v-for="(_, idx) in sections" :key="_.id" class="mb-8">
        <v-skeleton-loader type="heading" class="mb-3" />
        <v-skeleton-loader type="text" class="mb-3" width="50%" />
        <v-skeleton-loader type="button" width="200" />
        <v-divider v-if="idx !== sections.length - 1" class="mt-5" />
      </div>
    </template>
    <template #default>
      <template v-for="(section, idx) in sections">
        <RaiSetupGroup :key="section.title" :title="section.title">
          <p>{{ section.description }}</p>
          <RaiLink :href="section.goTo.href" external>
            {{ section.goTo.text }}
          </RaiLink>
        </RaiSetupGroup>
        <v-divider v-if="idx !== sections.length - 1" :key="idx" class="mb-4" />
      </template>
    </template>
  </RaiSetup>
</template>

<script>
import { RaiSetup, RaiSetupGroup, RaiLink, useResponsiveness } from "@/core-ui";

export default {
  name: "TextWithLinksStep",
  components: {
    RaiSetup,
    RaiSetupGroup,
    RaiLink,
  },
  extends: RaiSetup,
  mixins: [useResponsiveness()],
  props: {
    title: {
      type: String,
      default: "",
    },
    sections: {
      type: Array,
      default: () => [],
    },
    hideNext: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
